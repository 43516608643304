'use strict';

angular.module('managerApp').directive('ibanInput', function (procedureTools) {
    return {
        require: 'ngModel',
        link: function (_scope, _element, _attributes, _modelVal) {
            _modelVal.$validators.checkInput = (val) => {
                return procedureTools.isValidIBAN(val);
            };
            _scope.$watch('val', () => {
                _modelVal.$validate();
            });
        }
    };
});